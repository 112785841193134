import React from "react"

import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"

const PageThumbnailContainer = styled.div`
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-family: "Libre Franklin", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 24px;
  text-decoration: none;
  align-items: stretch;

  @media (max-width: 700px) {
    padding: 24px 0;
  }
`

const PageThumbnailHeader = styled.div`
  text-align: center;
  padding-bottom: 16px;
  position: relative;
`

const PageThumbnailTitle = styled.h3`
  font-family: "Libre Franklin", sans-serif;
  font-size: 20px;
  padding-bottom: 4px;
  color: #202020;
  line-height: 1.75rem;
`

const PageThumbnailSubtitle = styled.p`
  font-size: 14px;
  font-weight: normal;
  color: gray;
  line-height: 1.2rem;
`

const PageThumbnailImg = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

class PageThumbnail extends React.Component {
  state = {
    isVisible: false,
    shown: false,
  }

  handleVisibilityChange = isVisible => {
    const { shown } = this.state
    if (!shown && isVisible) {
      this.setState({ isVisible, shown: true })
      // this.playPauseAnim.play();
    }
  }

  render() {
    const { children } = this.props
    const title = this.props.title
    const subtitle = this.props.subtitle
    const subtitleTwo = this.props.subtitleAux
    return (
      <VisibilitySensor
        partialVisibility
        onChange={this.handleVisibilityChange}
      >
        <PageThumbnailContainer>
          <PageThumbnailHeader>
            <PageThumbnailTitle>{title}</PageThumbnailTitle>
            <PageThumbnailSubtitle>{subtitle}</PageThumbnailSubtitle>
            {subtitleTwo ? (
              <PageThumbnailSubtitle>{subtitleTwo}</PageThumbnailSubtitle>
            ) : null}
          </PageThumbnailHeader>
          <PageThumbnailImg>{children}</PageThumbnailImg>
        </PageThumbnailContainer>
      </VisibilitySensor>
    )
  }
}

export default PageThumbnail
