import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import SectionContainer from "../components/SectionContainer"
import { Container } from "../components/Grid"
import { AccentHeader, PageTitle } from "../components/Elements/index"
import PageThumbnail from "../components/PageThumbnail"
import PageThumbnailList from "../components/PageThumbnailList"

const PageThumbnailLink = styled(props => <Link {...props} />)`
  width: 100%;
  display: block;
  vertical-align: bottom;

  @media (min-width: 700px) {
    display: inline-block;
    width: 50%;
  }

  @media (min-width: 1024px) {
    display: inline-block;
    width: 25%;
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <SectionContainer>
      <Container>
        <ThemeProvider
          theme={{
            dividerColor: "#ffd504;",
          }}
        >
          <AccentHeader size="large">Welcome</AccentHeader>
        </ThemeProvider>
        <PageTitle>2024 Award Submissions</PageTitle>
      </Container>
    </SectionContainer>
    <SectionContainer>
      <Container>
        <PageThumbnailList>

          <PageThumbnailLink to="/2024/celebration/">
            <PageThumbnail title="CAMZYOS Celebration" subtitle="Best Launch, Best Rare Disease & Best Professional categories">
              <Img fluid={data.celebration.childImageSharp.fluid} alt="CAMZYOS Celebration" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/2024/merz-fight/">
            <PageThumbnail title="Fight of a Lifetime" subtitle="Best Professional Campaign">
              <Img fluid={data.xeomin.childImageSharp.fluid} alt="Merz-fight" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/2024/takeda/">
            <PageThumbnail title="Navigating" subtitle="Best Managed Markets Campaign">
              <Img fluid={data.takeda.childImageSharp.fluid} alt="Navigating" />
            </PageThumbnail>
          </PageThumbnailLink>

        </PageThumbnailList>
      </Container>
    </SectionContainer>
    {/*<SectionContainer>
      <Container>
        <ThemeProvider
          theme={{
            dividerColor: "#ffd504;",
          }}
        >
          <AccentHeader size="large">Welcome</AccentHeader>
        </ThemeProvider>
        <PageTitle>2023 Award Submissions</PageTitle>
      </Container>
    </SectionContainer>*/}
    {/*<SectionContainer>
      <Container>
        <PageThumbnailList>

          <PageThumbnailLink to="/kimmtrak-connect/">
            <PageThumbnail title="KimmTrak Connect" subtitle="Best Patient Engagement Campaign">
              <Img fluid={data.kimmtrakConnect.childImageSharp.fluid} alt="KimmTrak Connect" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/genicos/">
            <PageThumbnail title="Genicos" subtitle="Best Agency Campaign">
              <Img fluid={data.genicos.childImageSharp.fluid} alt="Genicos" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/lab-hero/">
            <PageThumbnail title="Lab Hero" subtitle="Best Social Media Campaign and Best Professional Campaign — Digital">
              <Img fluid={data.labHero.childImageSharp.fluid} alt="Lab Hero" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/biomarin-voxogo/">
            <PageThumbnail title="It’s Go Time with VOXZOGO" subtitle="Best Rare Disease Campaign">
              <Img fluid={data.bioMarinVoxogo.childImageSharp.fluid} alt="BioMarin Voxogo" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/folr1/">
            <PageThumbnail title="Where's FOLR1" subtitle="Best Medical Device Campaign">
              <Img fluid={data.folr1.childImageSharp.fluid} alt="Where's FOLR1" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/pluvicto-consumer-print/">
            <PageThumbnail title="PLUVICTO" subtitle="Best Consumer Campaign-Print">
              <Img fluid={data.pluvicto1.childImageSharp.fluid} alt="PLUVICTO Consumer Print" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/pluvicto-consumer-digital/">
            <PageThumbnail title="PLUVICTO" subtitle="Best Consumer Campaign-Digital">
              <Img fluid={data.pluvicto2.childImageSharp.fluid} alt="PLUVICTO Consumer Digital" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/pluvicto-professional-digital/">
            <PageThumbnail title="PLUVICTO" subtitle="Best Professional Campaign-Digital">
              <Img fluid={data.pluvicto3.childImageSharp.fluid} alt="PLUVICTO Professional Digital" />
            </PageThumbnail>
          </PageThumbnailLink>

          <PageThumbnailLink to="/dse/">
            <PageThumbnail title="DSE" subtitle="Best nonbranded consumer campaign">
              <Img fluid={data.dse.childImageSharp.fluid} alt="DSE" />
            </PageThumbnail>
          </PageThumbnailLink>


          

        </PageThumbnailList>
      </Container>
    </SectionContainer>*/}
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    celebration: file(relativePath: { eq: "2024/thumbs/celebration.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    xeomin: file(relativePath: { eq: "2024/thumbs/xeomin.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    takeda: file(relativePath: { eq: "2024/thumbs/takeda.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    kimmtrakConnect: file(relativePath: { eq: "2023/thumbs/kimmtrakconnect.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    genicos: file(relativePath: { eq: "2023/thumbs/genicos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    labHero: file(relativePath: { eq: "2023/thumbs/lab-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    bioMarinVoxogo: file(
      relativePath: { eq: "2023/biomarin-voxogo/banner2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    folr1: file(relativePath: { eq: "2023/thumbs/folr1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    pluvicto1: file(relativePath: { eq: "2023/thumbs/pluvicto1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    pluvicto2: file(relativePath: { eq: "2023/thumbs/pluvicto2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    pluvicto3: file(relativePath: { eq: "2023/thumbs/pluvicto3.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    dse: file(relativePath: { eq: "2023/thumbs/dse.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    cma: file(relativePath: { eq: "2022/thumbs/cma.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    rabavert: file(relativePath: { eq: "2022/thumbs/rabavert.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    heartofhealth: file(
      relativePath: { eq: "2022/heartofhealth/banner1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    
    bioMarinRisingLove: file(
      relativePath: { eq: "2022/biomarin-rising-love/LaptopInKitchen.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    edwardsPascal: file(
      relativePath: { eq: "2022/edwards-pascal/image1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    tippingPoint: file(
      relativePath: { eq: "2022/tipping-point/image-02.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    onionOrange: file(relativePath: { eq: "2022/onion-orange/image-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    rochePanTrk: file(relativePath: { eq: "2022/roche-pan-trk/banner1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    parsabiv: file(relativePath: { eq: "2022/thumbs/parsabiv.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    amgenEsa: file(relativePath: { eq: "2022/thumbs/amgen.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neulasta: file(relativePath: { eq: "2022/thumbs/neulasta.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    tgct: file(relativePath: { eq: "2022/thumbs/tgct.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    xgeva: file(relativePath: { eq: "2022/xgeva/image1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    onureg: file(relativePath: { eq: "2022/thumbs/onureg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
