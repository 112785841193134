import React from "react"
import styled from "styled-components"

const PageThumbnailListContainer = styled.div`
  display: table;
  width: 100%;
`

class PageThumbnailList extends React.Component {
  state = {
    isVisible: false,
    shown: false,
  }

  handleVisibilityChange = isVisible => {
    const { shown } = this.state
    if (!shown && isVisible) {
      this.setState({ isVisible, shown: true })
      // this.playPauseAnim.play();
    }
  }

  render() {
    const { children } = this.props
    return <PageThumbnailListContainer>{children}</PageThumbnailListContainer>
  }
}

export default PageThumbnailList
